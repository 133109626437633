body, html {
  height: 100%;
  background-color: black;
  margin: 0;
}

.background-container {
  background-image: url('./logo.jpg');
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
}

.menu-choices {
  position: absolute;
  top: 61%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.menu-semaine,
.menu-weekend,
.menu-boisson {
  background-color: #99773C;
  color: black;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center; 
}



.menu-link {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 90px;
  border-radius: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.menu-link:hover {
  background-color: #6d6b6b;
}

.menu-title {
  font-size: 35px;
  font-weight: bold;
  color: '#CD853F';
}

.menu-icon {
  width: 80px; 
  height: 80px; 
  margin-right: 10px; 
}

