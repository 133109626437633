button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: #99773C;
    color: black;
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    text-align: center; 
}

.button:hover {
    background-color: #6d6b6b;
}